// src/components/HomePage.js

import React from "react";
import { ProgramCard } from "../components/ProgramCard";
import HorizontalCarousel from "../components/AcademicPartnerCarousel";
import { FaqAccordion } from "../components/FaqAccordion";

import { CarouselWithContent } from "../components/CarouselWithContent";

const HomePage = () => {
  return (
    <div>
      {/* First Section */}
      <div className="bg-[#243B8E] flex flex-col md:flex-row items-center px-10">
        <div className="text-white flex-grow">
          <h1 className="mb-4 text-4xl md:text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-white dark:text-white">
            <br/>
            Capai Masa Depan Gemilang dengan Peningkatan Keahlian Digital
          </h1>
          
          <br />
          <p className="mb-4 text-2xl md:text-2xl">
            Tingkatkan keterampilan Anda dengan para ahli. Cari kursus online, magang,
            dan intensif di DNA. Mulailah jalan Anda menuju kesuksesan hari ini! »
          </p>
        </div>
        <img src="/home_picture.png" alt="home" className="w-full md:w-1/3" />
      </div>

      {/* Second Section */}
      
      <div className="p-10" id="program">
        <h1 class="mb-4 text-4xl font-extrabold text-center text-[#192A65]">
          PROGRAM DNA
        </h1>

        <p class="mb-4 text-2xl text-center text-[#1976D2]">
          Program untuk meningkatkan skill Anda
        </p>

        <br />
        {/* COURSES LIST */}
        <div className="flex flex-wrap gap-4 justify-center">
          <ProgramCard
            title="Kursus"
            desc="Rangkaian kursus online dengan kurikulum terstruktur untuk berbagai keterampilan."
            imgSrc={"/vector/kursus.png"}
          />
          <ProgramCard
            title="Bootcamp"
            desc="Program intensif yang berfokus pada pengembangan keterampilan khusus dalam waktu singkat."
            imgSrc={"/vector/bootcamp.png"}
          />
          <ProgramCard
            title="Karir"
            desc="Informasi tentang pengembangan karier, pemetaan karier, dan saran karier."
            isComingSoon={true}
            imgSrc={"/vector/karir.png"}
          />
          <ProgramCard
            title="Magang"
            desc="Kesempatan untuk magang di perusahaan-perusahaan terkemuka untuk mendapatkan pengalaman langsung."
            imgSrc={"/vector/magang.png"}
          />
          <ProgramCard
            title="Lowongan Kerja"
            desc="Daftar lowongan pekerjaan dari berbagai perusahaan mitra."
            isComingSoon={true}
            imgSrc={"/vector/lowongan kerja.png"}
          />
        </div>
      </div>

      {/* Seperator */}
      <div className="my-6 flex justify-center">
        <div className="w-2/5 h-2 bg-[#243B8E] rounded-lg"></div>
      </div>

      {/* Third Section */}
      <div className="p-10" id="solusi-pendidikan">
        <h1 class="mb-4 text-4xl font-extrabold text-center text-[#192A65]">
          SOLUSI PENDIDIKAN
        </h1>

        <p class="mb-4 text-2xl text-center text-[#1976D2]">
          Cari solusi untuk masalah kampus dan institusi pendidikan Anda
        </p>

        <div className="flex flex-wrap gap-4 justify-center">
          <ProgramCard
            title="Tes Minat Bakat"
            imgSrc={"/vector/tes minat bakat.png"}
          />
          <ProgramCard title="Ekskul" imgSrc={"/vector/eskul.png"} />
          <ProgramCard
            title="Kelas Industri"
            imgSrc={"/vector/kelas industri.png"}
          />
          <ProgramCard
            title="SMA/SMK Binaan"
            isComingSoon={true}
            imgSrc={"/vector/sma_smk binaan.png"}
          />
          <ProgramCard
            title="Kampus Mitra"
            isComingSoon={true}
            imgSrc={"/vector/kampus mitra.png"}
          />
        </div>
      </div>

      {/* Seperator */}
      <div className="my-6 flex justify-center">
        <div className="w-2/5 h-2 bg-[#243B8E] rounded-lg"></div>
      </div>

      {/* Fourth Section */}
      <div className="p-10" id="solusi-perusahaan">
        <h1 class="mb-4 text-4xl font-extrabold text-center text-[#192A65]">
          SOLUSI PERUSAHAAN
        </h1>

        <p class="mb-4 text-2xl text-center text-[#1976D2]">
          Cari solusi untuk masalah kampus dan institusi pendidikan Anda
        </p>

        <div className="flex flex-wrap gap-4 justify-center">
          <ProgramCard
            title="Company Training"
            imgSrc={"/vector/company training.png"}
          />

          <ProgramCard
            title="Digital Talent Hiring"
            isComingSoon={true}
            imgSrc={"/vector/digital talent hiring.png"}
          />
        </div>
      </div>

      {/* Profiles */}
      <div className="p-10 bg-[#BAD6F2]">
        <h1 class="mb-4 text-4xl font-extrabold text-center text-[#243B8E]">
          Yuk, intip profil pengguna DNA!
        </h1>
        <br/>
        <div className="flex flex-wrap gap-4 justify-center h-auto">
          {/* <ProfileCard />
          <ProfileCard />
          <ProfileCard />
          <ProfileCard />
          <ProfileCard />
          <ProfileCard /> */}
          <CarouselWithContent/>
          {/* <TestimonialCarousel/> */}
        </div>
      </div>

      {/* Seperator */}
      <br></br>
      <div className="my-6 flex justify-center">
        <div className="w-2/3 h-2 bg-[#243B8E] rounded-lg"></div>
      </div>

      <div className="p-10">
        <h1 class="mb-4 text-4xl font-extrabold text-center text-[#192A65]">
          100+ Mitra SMK dan Perguruan Tinggi
        </h1>
        <br />
        <HorizontalCarousel />
        <br />
        <h1 class="mb-4 text-4xl font-extrabold text-center text-[#192A65]">
          Mitra Industri
        </h1>
        <br />
        <HorizontalCarousel />
      </div>
      <br />
      {/* FAQ SECTION */}
      <div className=" bg-[#BAD6F2]">
        <div className="pt-10 mb-3">
          <h3 className="mb-4 text-4xl font-extrabold text-[#243B8E] text-center">
            FAQ
          </h3>
          <h6 class="mb-4 text-2xl text-center text-[#1976D2] font-medium">
            Pertanyaan tentang Digital Nusantara A
          </h6>
        </div>
        <div className="p-10 mx-8">
          <FaqAccordion
            id={1}
            header="Apa itu Digital Nusantara A (DNA)?"
            content="Digital Nusantara A (DNA) adalah "
          />
          <FaqAccordion
            id={2}
            header="Apa itu Digital Nusantara A (DNA)?"
            content="Digital Nusantara A (DNA) adalah "
          />
          <FaqAccordion
            id={3}
            header="Apa itu Digital Nusantara A (DNA)?"
            content="Digital Nusantara A (DNA) adalah "
          />
          <FaqAccordion
            id={4}
            header="Apa itu Digital Nusantara A (DNA)?"
            content="Digital Nusantara A (DNA) adalah "
          />
          <FaqAccordion
            id={5}
            header="Apa itu Digital Nusantara A (DNA)?"
            content="Digital Nusantara A (DNA) adalah "
          />
        </div>
      </div>
      <br />
      <br />
      {/* BLOG & ARTICLE SECTION */}
    </div>
  );
};

export default HomePage;
