import './App.css';
import React from 'react';
import { NavbarWithMegaMenu } from './components/Header';
import { Footer } from './components/Footer';
import HomePage from './pages/Home.js';
import BackToTopButton from './components/BackToTopButton.js';

function App() {
  return (
    <div>
      <NavbarWithMegaMenu/>
      <HomePage/>
      <Footer/>  
      <BackToTopButton />
    </div>
  );
}



export default App;
