import { Carousel, Typography, IconButton } from "@material-tailwind/react";
 
export function CarouselWithContent() {
  return (
    <div className="h-full w-full lg:w-3/4">
      <Carousel 
        className="rounded-xl"
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-black" : "w-4 bg-black/50"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
        prevArrow={({ handlePrev }) => (
          <IconButton
            variant="text"
            color="white"
            size="lg"
            onClick={handlePrev}
            className="!absolute lg:top-2/4 top-20 left-4 -translate-y-2/4 z-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            variant="text"
            color={window.innerWidth < 768 ? "white" : "black"}
            size="lg"
            onClick={handleNext}
            className="!absolute lg:top-2/4 top-20 !right-4 -translate-y-2/4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </IconButton>
        )}
      >
        <div className="relative h-full w-full lg:flex">
          <div className="lg:w-2/5">
            <img
              src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
              alt=""
              className="h-full w-full object-cover "
            />
          </div>
          <div className="inset-0 grid lg:w-3/5  items-center bg-white ">
            <div className="lg:p-24 p-5">
              
              <Typography
                variant="lead"
                color="black"
                className="mb-12 opacity-80"
              >
                It is not so much for its beauty that the forest makes a claim
                upon men&apos;s hearts, as for that subtle something, that quality
                of air that emanation from old trees, that so wonderfully changes
                and renews a weary spirit.
              </Typography>
              
            </div>
          </div>
        </div>
        <div className="relative h-full w-full lg:flex">
          <div className="lg:w-2/5">
            <img
              src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
              alt=""
              className="h-full w-full object-cover "
            />
          </div>
          <div className="inset-0 grid lg:w-3/5  items-center bg-white ">
            <div className="lg:p-24 p-5">
              
              <Typography
                variant="lead"
                color="black"
                className="mb-12 opacity-80"
              >
                It is not so much for its beauty that the forest makes a claim
                upon men&apos;s hearts, as for that subtle something, that quality
                of air that emanation from old trees, that so wonderfully changes
                and renews a weary spirit.
              </Typography>
              
            </div>
          </div>
        </div>
        <div className="relative h-full w-full lg:flex">
          <div className="lg:w-2/5">
            <img
              src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
              alt=""
              className="h-full w-full object-cover "
            />
          </div>
          <div className="inset-0 grid lg:w-3/5  items-center bg-white ">
            <div className="lg:p-24 p-5">
              
              <Typography
                variant="lead"
                color="black"
                className="mb-12 opacity-80"
              >
                It is not so much for its beauty that the forest makes a claim
                upon men&apos;s hearts, as for that subtle something, that quality
                of air that emanation from old trees, that so wonderfully changes
                and renews a weary spirit.
              </Typography>
              
            </div>
          </div>
        </div>
        
      </Carousel>
    </div>
  );
}