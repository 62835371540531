import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
 
function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    );
  }

export function FaqAccordion({header, content, id}) {
  const [open, setOpen] = React.useState(1);
 
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
 
  return (
    <>
      <Accordion open={open === id} icon={<Icon id={id} open={open} />} className="mb-2 rounded-lg border-2 border-[#243B8E] bg-white px-4">
        <AccordionHeader
          onClick={() => handleOpen(id)}
          className={`font-bold  transition-colors ${
            open === id ? "opacity-90 text-[#243B8E] hover:opacity-100 hover:!text-[#243B8E]" : "opacity-80 text-[#243B8E] hover:text-[#243B8E] hover:opacity-90"
          }`}
        >
          {header}
        </AccordionHeader>
        
        <AccordionBody className="pt-0 text-base font-normal">
          <br/>{content}
        </AccordionBody>
      </Accordion>
    </>
  );
}