import React from 'react';
import Slider from 'react-slick';

// Import react-slick styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AcademicPartnerCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Number of slides to show at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      <div className="">
       <div className="bg-[#243B8E] relative w-40 h-40 border-2 border-[#243B8E] rounded-xl"></div>
      </div>
      {/* Add more slides here */}
    </Slider>
  );
}

export default AcademicPartnerCarousel;
