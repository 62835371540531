/* eslint-disable no-unused-vars */
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

export function ProgramCard({ title, desc, isComingSoon, imgSrc }) {
  return (
    <Card className="mt-6 w-96 border-2 border-[#243B8E] relative ">
      {/* Conditional rendering of the badge */}
      {isComingSoon && (
        <div className="absolute top-0 right-0 transform translate-x-3 -translate-y-1/2 z-10">
          <div className="bg-[#FFE500] text-[#4F4F51] text-lg font-bold px-8 py-2 rounded-full">
            Coming Soon !
          </div>
        </div>
      )}
      <CardBody className="text-center">
        <div className="bg-[#243B8E] relative h-56 border-2 border-[#243B8E]">
          <img
            src={imgSrc}
            alt=""
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
        <br />
        <Typography variant="h2" className="mb-2 text-[#243B8E]">
          {title}
        </Typography>
        <Typography className="text-[#243B8E]">{desc}</Typography>
      </CardBody>
      <CardFooter className="pt-0 text-center">
        <Button className="bg-[#243B8E]">Info Lengkap</Button>
      </CardFooter>
    </Card>
  );
}
